<template>
  <img
    src="/_nuxt_home_page/general/linkedin.svg"
    alt="linkedin"
    loading="lazy"
    width="24"
    height="24"
  />
</template>

<script>
export default {};
</script>

<style></style>
