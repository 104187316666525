<template>
  <div class="overflow-x-hidden leading-relative">
    <experiment-toggle name="CookieBanner">
      <template #a />
      <template #b>
          <CookieBannerV1 />
      </template>
      <template #c>
          <CookieBannerV2 />
      </template>
    </experiment-toggle>

    <experiment-toggle name="home-may-campaign">
      <template #a />
      <template #b>
        <MayCampaignBanner />
      </template>
      <template #c>
        <MayCampaignBanner background-color="bg-[#ffbd00]" />
      </template>
    </experiment-toggle>

    <experiment-toggle name="airLoveInTheAirCampaign">
      <template #a />
      <template #b>
        <spring-campaign-banner />
      </template>
    </experiment-toggle>
    <experiment-toggle name="homAutumnCampaign">
      <template #a />
      <template #b>
        <autumn-campaign-banner />
      </template>
    </experiment-toggle>
    <experiment-toggle name="homChristmasCampaign">
      <template #a />
      <template #b>
        <christmas-campaign-banner />
      </template>
    </experiment-toggle>
    <Highlight />
    <Header />
    <main>
      <Nuxt />
    </main>
    <LazyHydrate when-visible>
      <Footer />
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import MayCampaignBanner from '~/components/experiments/be-1088-urgency-banner/UrgencyBanner.vue';
import CookieBannerV1 from '~/components/experiments/be-1101-cookie-banner/CookieBannerV1.vue';
import CookieBannerV2 from '~/components/experiments/be-1101-cookie-banner/CookieBannerV2.vue';
import AutumnCampaignBanner from '~/components/experiments/be-619-autumn-campaign/AutumnCampaignBanner.vue';
import ChristmasCampaignBanner from '~/components/experiments/be-693-Christmas-campaign/ChristmasCampaignBanner.vue';
import SpringCampaignBanner from '~/components/experiments/be-865-spring-campaign/SpringCampaignBanner.vue';
import ExperimentToggle from '~/components/experiments/ExperimentToggle.vue';
import Footer from '~/components/footer/Footer.vue';
import Header from '~/components/header/Header.vue';
import Highlight from '~/components/Highlight.vue';

export default {
  components: {
    LazyHydrate,
    Highlight,
    Header,
    Footer,
    ExperimentToggle,
    AutumnCampaignBanner,
    ChristmasCampaignBanner,
    SpringCampaignBanner,
    MayCampaignBanner,
    CookieBannerV1,
    CookieBannerV2,
  },
  head() {
    const script = [];

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
      };
      script.push(attrs);
    }

    return {
      script,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

* {
  -webkit-font-smoothing: antialiased;
}

.skew {
  transform: skew(0deg, -1deg);
}

.unskew {
  transform: skew(0deg, 1deg);
}

.skew,
.unskew {
  backface-visibility: hidden;
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
</style>
