<template>
  <div class="mobile-date-picker">
    <div
      v-if="component"
      ref="modal"
      :class="{ hidden: !isExpanded }"
      class="calendar-wrapper fixed inset-x-0 bottom-0 bg-white h-full flex flex-col place-content-between z-10"
      style="z-index:10000;"
    >
      <div
        class="mobile-date-picker__modal__header h-12 flex place-content-end bg-white flex-shrink-0"
      >
        <div
          class="inline-block relative my-auto right-2 z-10 w-5 h-5 p-5"
          @click.stop.prevent="collapse()"
        >
          <div
            class="absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform rotate-45"
          />
          <div
            class="absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform -rotate-45"
          />
        </div>
      </div>
      <div
        class="w-full mobile-date-picker__week-days mx-auto px-2 grid grid-cols-7 mb-6 mt-2"
      >
        <span
          v-for="(weekday, index) in weekdays"
          :key="index"
          class="text-center text-md font-bold text-manatee-500 capitalize"
        >
          {{ weekday }}
        </span>
      </div>

      <div
        class="mobile-date-picker__modal__content flex overflow-y-scroll overscroll-contain"
      >
        <component
          :is="component"
          ref="calendar"
          :rows="rowCount"
          :columns="screens(2, 1)"
          :min-date="minimumDate"
          :attributes="calendarAttributes"
          :nav-visibility="'hidden'"
          :title-position="'left'"
          :locale="language"
          :masks="masks"
          :trim-weeks="true"
          @dayclick="dayClick"
          @hook:mounted="calendarLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { asyncLoadingStates } from '~/constants/states';

const storeComponentName = 'mobileDatePicker';
export default {
  data() {
    return {
      isExpanded: false,
      weekdays: [],
      state: asyncLoadingStates.awaiting,
      component: null,
      unsubscribeLoad: () => 1,
      asyncLoadingStates,
      shouldRender: false,
      unsusbscribeDatePickerEvents: () => 1,
      rowCount: 2,
      unsubscribe:()=>{}
    };
  },
  computed: {
    isEndDate() {
      return this.$store.state.mobileDatePicker.for === 'end';
    },
    dateOther() {
      return this.$store.state.mobileDatePicker.dateOther;
    },
    date() {
      return this.$store.state.mobileDatePicker.date;
    },
    minimumDate() {
      return this.$store.state.mobileDatePicker.minimumDate;
    },
    language() {
      return this.$store.state.language.lang;
    },
    masks() {
      return {
        weekdays: 'WW',
      };
    },
    calendarAttributes() {
      const attributes = [];
      if (this.date) {
        const dates = [this.date];
        attributes.push({
          order: 1,
          highlight: {
            style: {
              backgroundColor: '#f06b1f',
              borderRadius: '4px',
              width: '100%',
              height: '100%',
            },
            contentStyle: {
              color: '#fff',
            },
          },
          dates,
        });
      }

      if (this.dateOther) {
        attributes.push({
          order: 0,
          highlight: {
            style: {
              backgroundColor: '#fad2bb',
              borderRadius: '4px',
              width: '100%',
              height: '100%',
            },
            contentStyle: {
              color: '#000',
            },
          },
          dates: this.dateOther,
        });
      }

      return attributes;
    },
  },
  created() {
    this.unsubscribeLoad = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type !== 'components/load' ||
        this.component ||
        !mutation.payload.includes(storeComponentName) ||
        this.state === asyncLoadingStates.loading
      ) {
        return;
      }
      this.$store.commit('components/setState', {
        component: storeComponentName,
        state: asyncLoadingStates.loading,
      });
      this.state = asyncLoadingStates.loading;

      setTimeout(() => {
        this.component = () => ({
          component: import('v-calendar/lib/components/calendar.umd.min'),
          delay: 0,
        });
      });
    });

    this.unsusbscribeDatePickerEvents = this.$store.subscribe(
      (mutation, state) => {
        if (mutation.type !== 'mobileDatePicker/toggle') {
          return;
        }
        this.toggle();
      },
    );
  },
  beforeDestroy() {
    this.unsubscribe();
    this.unsubscribeLoad();
  },
  methods: {
    screens(value, defaultValue) {
      if (process.server) {
        return defaultValue;
      }
      return this.$screens(value, defaultValue);
    },
    calendarLoaded() {
       this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type !== "components/hashchangeCollapse") {
        return;
      }
      this.collapse()
    });
      setTimeout(() => {
        this.$store.commit('components/setState', {
          component: storeComponentName,
          state: asyncLoadingStates.loaded,
        });
        this.state = asyncLoadingStates.loaded;

        if (
          this.$store.state.components.toggleLIFO[
            this.$store.state.components.toggleLIFO.length - 1
          ] === storeComponentName
        ) {
          this.$store.commit('components/clearToggleLIFO');
          setTimeout(() => {
            this.expand();
          });
        }

        setTimeout(() => {
          if (this.weekdays.length) {
            return;
          }
          const days = document
            .querySelector('.vc-weeks')
            ?.querySelectorAll('.vc-weekday');
          if (!days) {
            return;
          }
          days.forEach((e) => this.weekdays.push(e.innerText));
        });
      });

      setTimeout(() => {
        this.rowCount = 12;
      }, 500);
    },

    getRef(name) {
      return new Promise((resolve) => {
        if (this.$refs[name]) {
          resolve(this.$refs[name]);
          return;
        }

        const timeout = setInterval(() => {
          if (this.$refs[name]) {
            clearInterval(timeout);
            resolve(this.$refs[name]);
          }
        }, 50);
      });
    },
    async findCurrentDayElement() {
      const calendar = (await this.getRef('calendar')).$el;
      const dateToScroll = this.date || this.dateOther || new Date();
      const [dateId] = dateToScroll.toISOString().split('T');
      const dateEl = calendar.querySelector(`.id-${dateId},#id-${dateId}`);
      return dateEl;
    },
    dayClick(event) {
      if (event.isDisabled) {
        return;
      }
      const date = new Date(event.date);
      date.setHours(12);
      this.$emit('dateChanged', { date, isEndDate: this.isEndDate });
      this.collapse();
    },
    collapse() {
      setTimeout(() => {
        if (window.location.hash === '#picker') {
          window.history.back();
        }
      });
      this.disableHighlight();
      this.isExpanded = false;
      this.resetDragging();
      this.$emit('toggled', false);
    },
    expand() {
      if (this.state !== asyncLoadingStates.loaded) {
        this.$store.commit('components/failToggle', storeComponentName);
        return;
      }
      window.location.hash = 'picker';
      this.dates = this.dateRange;
      this.isExpanded = true;
      this.enableHighlight();
      this.$emit('toggled', true);
      setTimeout(() => {
        const el = this.findCurrentDayElement();
        if (el && el.scrollIntoView) {
          el.scrollIntoView({
            block: 'center',
            inline: 'center',
          });
        }
      });
    },
    resetDragging() {
      this.isDragging = false;
      this.draggingDates = {};
    },
    async enableHighlight() {
      const modal = await this.getRef('modal');
      this.$store.commit('highlight/show', modal);
    },
    disableHighlight() {
      this.$store.dispatch('highlight/hide');
    },
    toggle() {
      this.shouldRender = true;
      if (this.isExpanded) {
        this.collapse();
      } else {
        this.expand();
      }
    },
  },
};
</script>

<style lang="scss">
.mobile-date-picker {
  &__modal {
    &__content {
      @apply mb-auto;
    }
  }
  &__week-days {
    max-width: 24rem;
  }

  .calendar-wrapper {
    max-height: 78vh;
    @media (max-height: 500px) {
      max-height: 85vh;
    }

    .vc-container {
      @apply w-full p-2 max-w-sm xs:max-w-full mx-auto border-0 rounded-none -mt-5;
      .vc-pane-container {
        // arrow nav
        .vc-arrows-container {
          @apply hidden;
        }

        //months grid
        .vc-pane-layout {
          //month container
          .vc-pane {
            //month name wrapper
            .vc-header {
              @apply sticky top-0 w-full mt-2 mb-1 bg-white py-1 z-20;
              &:first-child {
                @apply mt-1;
              }

              .vc-title {
                &:hover {
                  @apply text-black;
                }

                @apply font-cocogoose text-base font-bold;
              }
            }

            //days of month : grid
            .vc-weeks {
              //name of the day

              .vc-weekday {
                display: none;
              }

              // day cell of grid  -- contains highlights element which contains highlights from attributes
              .vc-day {
                @apply min-w-full;
                aspect-ratio: 1;
                //content of grid cell, contains day text as number also this the place that listens click event
                .vc-day-content {
                  @apply w-full h-full font-cocogoose text-base;
                  &:hover,
                  &:focus {
                    @apply bg-transparent font-normal;
                  }
                }

                &.is-not-in-month {
                  &.in-next-month {
                    @apply hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__modal {
    &__footer {
      &::before {
        content: '';
        background: linear-gradient(
          180deg,
          hsla(0, 0%, 100%, 0) 0,
          hsla(0, 0%, 96.1%, 0.92941176470588235) 65%,
          hsla(0, 0%, 96.1%, 0.9019607843137255)
        );
        @apply z-50 pt-10 absolute top-px w-full transform -translate-y-full pointer-events-none;
      }

      &__confirm {
        max-width: 88%;
        @media (min-width: 425px) {
          max-width: 77%;
        }
        @media (min-width: 576px) {
          max-width: 40%;
        }
        @media (min-width: 768px) {
          width: 30%;
        }
      }
    }
  }
}
</style>
