<template>
  <div v-show="expanded" :class="be604ukQuickWinsRunning?'top-14':'top-8'" class="absolute  z-40 w-24  rounded-md">
    <div>
      <div
        class="airport-picker__modal__caret inset-x-1/2 absolute bg-silver-100 border-t  border-l transform rotate-45 -translate-y-1/2 border-gray-200 z-10"
      />
      <div
        class="airport-picker__modal__header md:bg-silver-100 border-y  rounded-t-lg md:py-2.5 flex flex-col min-h-max shrink-0 flex-shrink-0"
      >
        <div class="md:hidden relative h-12 w-full bg-white place-content-end flex min-h-max">
          <div
            class="inline-block relative my-auto right-2 z-10 w-5 h-5 p-5  ml-auto"
          >
            <div
              class="absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform rotate-45"
            />
            <div
              class="absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform -rotate-45"
            />
          </div>
        </div>
        <div
          ref="country"
          class="airport-picker__modal__header__countries__country font-bold cursor-pointer py-3 px-4 whitespace-nowrap overflow-hidden flex-shrink-0"
        >
          {{ $i18n('general.time') }}
        </div>
      </div>
    </div>
    <div ref="timePicker" class="legacy-time-picker-desktop h-64 rounded-b-md border-athens border shadow-dropdown overflow-y-scroll  overscroll-contain">
      <template v-for="time in times">
        <div
          :key="time.value"
          role="button"
          tabindex="0"
          class="legacy-time-picker-desktop__item bg-white hover:bg-orange-500 hover:text-white px-4 py-0.5 "
          :class="{'legacy-time-picker-desktop__item--selected bg-orange-500 text-white':time.value === selectedValue}"
          @keypress.stop.prevent="selectionChanged(time.value)"
          @click.stop.prevent="selectionChanged(time.value)"
        >
          {{ time.title }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    times: {
      type: Array,
      default: () => [],
      required: false,
    },
    expanded: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    selectedValue: {
      type: String,
      default: () => '12:00',
      required: false,
    },
  },
  data() {
    return {
      fristLoad: false,
      be604ukQuickWinsRunning: false,

    };
  },
  watch: {
    expanded(newValue) {
      if (!newValue) {
        return;
      }
      this.$nextTick(() => {
        if (!this.$refs.timePicker) {
          return;
        }
        const element = this.$refs.timePicker.querySelector('.legacy-time-picker-desktop__item--selected');
        if (!element) {
          return;
        }
        if (!this.fristLoad) this.moveTimePickerDown();
        this.fristLoad = true;
        const rectParent = this.$el.getBoundingClientRect();
        const rect = element.getBoundingClientRect();
        this.$el.scrollTop = rect.top - rectParent.top;// ({ block: 'nearest', inline: 'start' });

        this.$refs.timePicker.scroll({
          top:
        this.$refs.timePicker.querySelector(
          '.legacy-time-picker-desktop__item--selected',
        ).offsetTop - 70,
          behavior: 'smooth',
        });
      });
    },
  },
  created() {
    this.be604ukQuickWinsRunning = this.$store?.getters?.experimentVariant(this.name) === 'b';
  },

  methods: {
    selectionChanged(value) {
      this.$emit('selectionChanged', value);
    },
    moveTimePickerDown() {
      this.$refs.timePicker.scrollTop = 1340;
    },
  },
};

</script>

<style lang="scss">
.airport-picker {
  &__modal {
    @apply h-full md:h-auto;
    &__caret{
      width:14px;
      height:14px;
      left:18px;
    }
    &__content{
      @supports (-webkit-overflow-scrolling: touch) {
        display: flex;
        align-content: stretch;
      }
      &::after{
        @apply hidden md:block absolute z-20 pt-10 pointer-events-none bottom-0 w-full;
        content:'';
        // background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,hsla(0,0%,96.1%,.92941176470588235) 65%,hsla(0,0%,96.1%,.9019607843137255));
      }
      &__gradient{
        @apply sticky -bottom-4 w-full -mb-px;
        background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,hsla(0,0%,96.1%,.92941176470588235) 65%,hsla(0,0%,96.1%,.9019607843137255));
      }
    }
  }
}
.legacy-time-picker-desktop{
     scrollbar-width: thin;
       &::-webkit-scrollbar{
         width:8px;
         @apply bg-silver-100;
       }
       &::-webkit-scrollbar-thumb{
         @apply bg-gray-400 rounded-md;
       }
}
</style>
