<template>
  <img
    src="/_nuxt_home_page/general/user.svg"
    loading="lazy"
    alt="user"
    width="auto"
    height="auto"
  />
</template>

<script>
export default {};
</script>

<style></style>
