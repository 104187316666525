<template>
  <div class="mobile-date-picker">
    <slot
      :collapse="collapse"
      :toggle="toggle"
      :expand="expand"
      :loading="loadingState === asyncLoadingStates.loading"
    />
  </div>
</template>

<script>
import { asyncLoadingStates } from '~/constants/states';

export default {
  props: {
    isEndDate: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    date: {
      type: Date,
      default: () => null,
    },
    minimumDate: {
      type: Date,
      default: () => new Date(),
    },
    dateOther: {
      type: Date,
      default: () => undefined,
    },
  },
  data() {
    return {
      asyncLoadingStates,
      unsusbscribe: () => 1,
    };
  },
  computed: {
    loadingState() {
      return this.$store.state.components.mobileDatePicker.state;
    },
  },
  methods: {
    collapse() {},
    toggle() {
      this.$store.commit('mobileDatePicker/toggle', {
        date: this.date,
        minimumDate: this.minimumDate,
        dateOther: this.dateOther,
        for: this.isEndDate ? 'end' : 'start',
      });
    },
    expand() {
    },
  },
};
</script>

<style></style>
