<template>
  <div
    class="block md:hidden fixed top-0 left-0 w-full h-screen bg-white z-50 overflow-y-scroll"
  >
    <div>
      <!-- Loop over months -->
      <!-- Weekday Labels -->
      <div class="w-full z-10 fixed bg-white">
        <div
          class="h-12 flex place-content-end bg-white flex-shrink-0"
          @click="$emit('collapseDate')"
        >
          <div
            role="button"
            tabindex="0"
            class="inline-block relative my-auto right-2 z-10 w-5 h-5 p-5"
          >
            <div
              class="absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform rotate-45"
            ></div>
            <div
              class="absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform -rotate-45"
            ></div>
          </div>
        </div>
        <div class="w-full mx-auto px-2 grid grid-cols-7 mb-6 mt-2">
          <div
            v-for="day in daysOfWeek"
            :key="day"
            class="text-center text-md font-bold text-manatee-500 capitalize"
          >
            {{ day }}
          </div>
        </div>
      </div>
      <div class="pt-24 px-3">
        <div
          v-for="(month, index) in generatedMonths"
          :key="index"
          class="mb-5"
        >
          <!-- Month Label -->
          <div class="sticky-month my-2 font-bold leading-6 text-gray-800 px-[18px]">
            {{ month.label }}
          </div>
          <!-- Dates Grid -->
          <div
            class="grid p-[5px]"
            style="min-width: 250px; grid-template-columns: repeat(7, 1fr)"
          >
            <div
              v-for="date in month.dates"
              :key="date"
              class="calendar-day"
              :class="dateClass(month.year, month.month, date)"
              @click="selectDate(month.year, month.month, date)"
            >
              <span>
                {{ date }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from "~/static/translations";
const bodyScrollLock = require("body-scroll-lock");

export default {
  props: ["minDate", "defaultTime", "date", "dateOther"],
  data() {
    return {
      selectedDate: new Date(this.date),
    };
  },
  computed: {
    langData() {
      return translations[this.$store.state.language.lang] || {};
    },
    daysOfWeek() {
      return this.langData.days || ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    },
    generatedMonths() {
      return Array.from({ length: 12 }, (_, i) => this.generateMonthData(i));
    },
  },
  mounted() {
    const { disableBodyScroll } = bodyScrollLock;
    this.$nextTick(() => {
      disableBodyScroll(this.$el);
    });
  },
  beforeDestroy() {
    const { enableBodyScroll } = bodyScrollLock;
    this.$nextTick(() => {
      enableBodyScroll(this.$el);
    });
  },
  methods: {
    // Helper function for dynamic classes
    dateClass(year, month, date) {
      if (this.isBeforeMinDate(year, month, date)) return "text-gray-400";
      if (this.isSelected(year, month, date))
        return "rounded bg-orange-500 text-white";
      if (this.isOtherSelected(year, month, date))
        return "rounded bg-[#FAD2BB]";
      return "";
    },
    generateMonthData(offset) {
      const date = new Date(this.minDate.getTime());
      date.setDate(1); // Set to the first of the month
      const targetMonth = date.getMonth() + offset;
      const yearOffset = Math.floor(targetMonth / 12);
      const actualMonth = targetMonth % 12;

      date.setFullYear(date.getFullYear() + yearOffset);
      date.setMonth(actualMonth);

      const label = `${
        this.langData.months?.[actualMonth] || this.generateMonthName()
      } ${date.getFullYear()}`;

      return {
        label,
        dates: this.dates(date.getMonth(), date.getFullYear()),
        month: date.getMonth(),
        year: date.getFullYear(),
      };
    },
    dates(month, year) {
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      const offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
      const placeholders = Array.from({ length: offset }, () => null);
      const monthDates = Array.from(
        { length: new Date(year, month + 1, 0).getDate() },
        (_, i) => i + 1
      );
      return [...placeholders, ...monthDates];
    },
    isBeforeMinDate(year, month, date) {
      return (
        new Date(year, month, date).setHours(0, 0, 0, 0) <
        new Date(this.minDate).setHours(0, 0, 0, 0)
      );
    },
    isSelected(year, month, date) {
      return (
        this.selectedDate?.getDate() === date &&
        this.selectedDate.getMonth() === month &&
        this.selectedDate.getFullYear() === year
      );
    },
    isOtherSelected(year, month, date) {
      return (
        this.dateOther?.getDate() === date &&
        this.dateOther.getMonth() === month &&
        this.dateOther.getFullYear() === year
      );
    },
    selectDate(year, month, date) {
      if (!this.isBeforeMinDate(year, month, date)) {
        this.selectedDate = new Date(year, month, date);
        this.$emit("selectDate", this.selectedDate);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-month{
    position: sticky;
    top: 103px;
    background-color: white;
    z-index: 5;
    margin: 0;
}
.calendar-day {
  aspect-ratio: 1;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 50% 50%;
  position: relative;
  min-height: 32px;
  z-index: 1;
  span {
    font-size: 1rem;
    height: 100%;
    line-height: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    user-select: none;
  }
}
</style>
