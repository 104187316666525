<template>
  <img
    src="/_nuxt_home_page/general/logo-white.svg"
    alt="Parkos-logo"
    class="w-auto h-[26px] md:w-24 md:h-8"
    width="81"
    height="26"
  />
</template>

<script>
export default {
  name: "Logo",
};
</script>
