import { asyncLoadingStates } from '~/constants/states';
/* eslint-disable no-shadow */
export const state = () => ({
  mobileDatePicker: { state: asyncLoadingStates.awaiting },
  datePickerStart: { state: asyncLoadingStates.awaiting },
  datePickerEnd: { state: asyncLoadingStates.awaiting },
  toggleLIFO: [],
});

export const mutations = {
  load(state, data) {
  },
  failToggle(state, data) {
    state.toggleLIFO.push(data);
  },
  setState(state, data) {
    state[data.component] = data;
  },
  clearToggleLIFO(state) {
    state.toggleLIFO = [];
  },
  hashchangeCollapse() {

  },
};

export const actions = {

};
