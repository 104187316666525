<template>
  <div
    v-show="showCookieBanner"
    style="z-index: 9999999; height: 100%"
    class="fixed w-full flex items-end bg-black bg-opacity-50"
  >
    <div class="w-full bg-white py-[30px] md:py-[60px]">
      <div class="xs:container px-5 md:px-10">
        <h2
          class="text-center leading-none md:text-left font-cocogoose text-lg mb-5"
        >
          {{ $i18n("general.cookie-banner-title") }}
        </h2>
        <div class="flex">
          <div class="md:mr-26 md:text-lg text-center md:text-left">
            <p>
              {{ $i18n("general.cookie-banner-content") }}
            </p>
          </div>
          <div class="hidden md:flex items-center gap-5">
            <div
              role="button"
              tabindex="-1"
              @click="acceptAll"
              class="rounded leading-none h-fit w-fit px-6 py-4 bg-orange-500 cursor-pointer hover:bg-orange-400 text-white font-cocogoose whitespace-nowrap"
            >
              {{ $i18n("general.yes-i-agree") }}
            </div>
            <div
              role="button"
              tabindex="-1"
              @click="declineAll"
              class="rounded leading-none h-fit w-fit px-6 py-4 bg-orange-500 cursor-pointer hover:bg-orange-400 text-white font-cocogoose whitespace-nowrap"
            >
              {{ $i18n("general.reject-all") }}
            </div>
          </div>
        </div>
        <div
          class="md:hidden flex justify-center items-center gap-2.5 md:gap-5 mt-5"
        >
          <div
            role="button"
            tabindex="-1"
            @click="acceptAll"
            class="rounded leading-none h-fit w-fit text-[14px] md:text-[16px] px-2.5 md:px-6 py-4 bg-orange-500 cursor-pointer hover:bg-orange-400 text-white font-cocogoose whitespace-nowrap"
          >
            {{ $i18n("general.yes-i-agree") }}
          </div>
          <div
            role="button"
            tabindex="-1"
            @click="declineAll"
            class="rounded leading-none h-fit w-fit text-[14px] md:text-[16px] px-2.5 md:px-6 py-4 bg-orange-500 cursor-pointer hover:bg-orange-400 text-white font-cocogoose whitespace-nowrap"
          >
            {{ $i18n("general.reject-all") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCookie,
  nginxDecodeUserId,
  setCookie,
} from "~/helpers/cookieHelpers";

export default {
  data() {
    return {
      showCookieBanner: false,
    };
  },
  mounted() {
    if (!getCookie("showCookieBanner")) {
      this.showCookieBanner = true;
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "63/24",
        dl_experiment_action: "impression",
        dl_experiment_value: "banner",
      });
      document.body.style.overflow = "hidden";
    }
  },
  beforeDestroy() {
    document.body.style.overflow = "visible";
  },
  methods: {
    acceptAll() {
      this.hideCookieBanner();
      window.dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "63/24",
        dl_experiment_action: "selection",
        dl_experiment_value: "accept_all",
      });
      this.sendEvent("accept_all", "Action Tracking", "CookieBanner");
    },
    declineAll() {
      this.hideCookieBanner();
      window.dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "63/24",
        dl_experiment_action: "selection",
        dl_experiment_value: "decline_all",
      });
      this.sendEvent("decline_all", "Action Tracking", "CookieBanner");
    },
    hideCookieBanner() {
      setCookie("showCookieBanner", "true", 7);
      this.showCookieBanner = false;
      document.body.style.overflow = "visible";
    },
    async sendEvent(ea, ec, el) {
      const { host } = window.location;
      const pageType = () =>
        window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;

      const visitorIdCookie = getCookie("uid");
      if (!visitorIdCookie) {
        console.log("[FLAGSHIP GTM] VisitorId is not defined");
        return;
      }
      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = getCookie("xpe");
      const flagshipEventsAPIEndpoint =
        this.$config.FLAGSHIP_EVENTS_API_BASE_URL;

      const response = await fetch(
        `${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cid: flagshipEnvironmentId,
            vid: visitorId,
            dl: document.location.href,
            ds: "APP",
            ev: 1,
            t: "EVENT",
            ea,
            ec,
            el,
          }),
        }
      );
      if (response.status !== 200) {
        console.error(
          `[FLAGSHIP GTM] Event could not be be sent, HTTP ${response.status}`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
