<template>
  <component :is="fragment ? 'Fragment' : 'FragmentFallback'">
    <slot v-if="active" :name="variant" />
    <slot v-else name="a" />
  </component>
</template>
  
  <script>
import FragmentFallback from "~/components/experiments/FragmentFallback.vue";

import { Fragment } from "vue-fragment";

export default {
  name: "ExperimentsToggle",
  components: {
    FragmentFallback,
    Fragment,
  },
  props: {
    name: {
      type: String,
      required: true,
    }, 
     fragment: {
      type: Boolean,
      default: () => true,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    active() {
      return this.$store?.getters?.isExperimentEnabled(this.name);
    },
    variant() {
      return this.$store?.getters?.experimentVariant(this.name);
    },
  },
  mounted() {
    this.$store.commit("experiments/addExperiment", {
      name: this.name,
      variant: this.variant,
    });
  },
  methods: {},
};
</script>
  