<template>
  <div
    class="absolute inset-x-0 top-0 bg-[#ffbd00] py-[7px] px-2 rounded-t -mb-6 z-1 flex justify-center"
  >
    <p class="text-[#123878] text-xs font-medium">
      {{ $i18n('general.three-min-to-book') }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    showAirportsDropdown: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  computed: {
    airport() {
      return this.$store.state.airport;
    },
    language() {
      return this.$store.state.language;
    },
    isSEDomain() {
      return this.language.lang.includes('se');
    },
    isSpanish() {
      return this.language.lang.includes('es');
    },
    isFrench() {
      return this.language.lang.includes('fr');
    },
    isDutch() {
      return this.language.lang.includes('nl');
    },
    isPLDomain() {
      return this.language.lang.includes('pl');
    },
    isDKDomain() {
      return this.language.lang.includes('dk');
    },
    isITDomain() {
      return this.language.lang.includes('it');
    },
    isDEDomain() {
      return this.language.lang.includes('de');
    },
    isSpecialLanguage() {
      return this.isFrench || this.isSpanish || this.isDutch;
    },
    fontSizeLower() {
      if (this.isSEDomain) {
        return 13;
      }
      if (this.isPLDomain) {
        return 18;
      }
      if (this.isDKDomain) {
        return 20;
      }
      if (this.isITDomain) {
        return 15;
      }
      if (this.isDEDomain) {
        return 17;
      }
      if (this.isDutch) {
        return 13;
      }
      return 16;
    },
  },
  methods: {
    selectValue(boolExpr, trueVal, falseVal) {
      return boolExpr ? trueVal : falseVal;
    },
  },
};
</script>
