<template>
  <div
    class="absolute top-full left-0 bg-white border mt-3 rounded-lg z-40 hidden md:block"
  >
    <span class="calendar-caret"> </span>
    <div class="relative">
      <!-- Navigation for changing month/year -->
      <div class="flex justify-between px-7 py-4 bg-silver-100">
        <button
          class="focus:outline-none"
          :disabled="isPrevDisabled"
          @click="prev"
        >
          <CaretDown
            :class="{ ' text-gray-400': isPrevDisabled }"
            class="rotate-90 duration-200 transition-all transform stroke-current"
          />
        </button>
        <!-- Display current month and year -->
        <span
          class="cursor-pointer font-semibold text-gray-800 leading-7"
          @click="toggleYearMonthSelector"
        >
          {{ currentMonthName }} {{ currentYear }}
        </span>
        <button
          class="focus:outline-none"
          :disabled="isNextDisabled"
          @click="next"
        >
          <CaretDown
            :class="{ ' text-gray-400': isNextDisabled }"
            class="-rotate-90 duration-200 transition-all transform stroke-current"
          />
        </button>
      </div>
      <!-- Month selection grid -->
      <div
        class="month-selector bg-silver-100 border border-gray-600 rounded-lg z-50 p-[5px] mt-[10px]"
        v-if="showYearMonthSelector"
      >
        <div class="flex justify-between">
          <button
            class="focus:outline-none"
            :disabled="isPrevDisabled"
            @click="prev"
          >
            <CaretDown
              :class="{ ' text-gray-400': isPrevDisabled }"
              class="rotate-90 duration-200 transition-all transform stroke-current"
            />
          </button>
          <!-- Display current month and year -->
          <span
            class="cursor-pointer font-semibold text-gray-800 leading-7"
            @click="toggleYearMonthSelector"
          >
            {{ currentYear }}
          </span>
          <button
            class="focus:outline-none"
            :disabled="isNextDisabled"
            @click="next"
          >
            <CaretDown
              :class="{ ' text-gray-400': isNextDisabled }"
              class="-rotate-90 duration-200 transition-all transform stroke-current"
            />
          </button>
        </div>
        <div
          style="grid-template-columns: repeat(3, 1fr)"
          class="grid gap-x-[5px] gap-y-[2px]"
        >
          <!-- Iterate over months and display each one -->
          <div
            v-for="month in months"
            :key="month"
            style="width: 48px"
            class="py-[6px] px-[2px] text-center cursor-pointer"
            :class="[
              isMonthSelected(month) ? 'bg-orange-500 text-white' : '',
              isMonthDisabled(month) ? 'text-gray-400 cursor-not-allowed' : '',
            ]"
            @click="selectMonth(month)"
          >
            {{ month }}
          </div>
        </div>
        <span class="month-caret"></span>
      </div>
      <!-- Days of the week and dates grid -->
      <div
        class="grid p-7"
        style="min-width: 250px; grid-template-columns: repeat(7, 1fr)"
      >
        <!-- Display days of the week -->
        <div
          v-for="day in daysOfWeek"
          :key="day"
          class="pt-1 pb-2 text-md text-center text-[#a0aec0] font-bold"
        >
          {{ day }}
        </div>
        <!-- Display dates of the month with placeholders for alignment -->
        <div
          v-for="dateNumber in dates"
          :key="dateNumber"
          class="calendar-day"
          :class="[
            'text-center cursor-pointer',
            isSelected(dateNumber) ? 'rounded bg-orange-500 text-white' : '',
            isDateDisabled(dateNumber) || !dateNumber
              ? 'text-gray-400 cursor-not-allowed'
              : '',
          ]"
          @click="selectDate(dateNumber)"
        >
          <span>
            {{ dateNumber }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from "~/static/translations";
import CaretDown from "~/components/icons/IconCaretDown.vue";

export default {
  props: ["minDate", "defaultTime", "date"],
  components: {
    CaretDown,
  },
  data() {
    const today = new Date();
    return {
      showYearMonthSelector: false,
      currentMonth: new Date(this.date).getMonth() || today.getMonth(),
      currentYear: new Date(this.date).getFullYear() || today.getFullYear(),
      selectedDate: new Date(this.date),
    };
  },
  computed: {
    langData() {
      return translations[this.$store.state.language.lang] || {};
    },
    daysOfWeek() {
      return this.langData.days || ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    },
    months() {
      return this.langData.monthsShort || this.generateDefaultMonths();
    },
    currentMonthName() {
      return (
        this.langData.months?.[this.currentMonth] || this.generateMonthName()
      );
    },
    // Adjusted dates property to include placeholders
    dates() {
      // Determine the day of the week for the 1st day of the current month
      const firstDayOfMonth = new Date(
        this.currentYear,
        this.currentMonth,
        1
      ).getDay();
      const offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

      // Create placeholders for empty grid slots
      const placeholders = Array.from({ length: offset }, () => null);

      // Create array for the actual days of the month
      const monthDates = Array.from(
        { length: this.currentMonthDays },
        (_, i) => i + 1
      );

      // Return the merged array
      return [...placeholders, ...monthDates];
    },
    currentMonthDays() {
      return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
    },
    isPrevDisabled() {
      return this.showYearMonthSelector
        ? this.isPrevYearDisabled
        : this.isPrevMonthDisabled;
    },
    isNextDisabled() {
      return this.showYearMonthSelector
        ? this.isNextYearDisabled
        : this.isNextMonthDisabled;
    },
    isPrevMonthDisabled() {
      return new Date(this.currentYear, this.currentMonth, 1) <= this.minDate;
    },
    isPrevYearDisabled() {
      return new Date(this.currentYear, 0, 1) <= this.minDate;
    },
    isNextMonthDisabled() {
      return false;
    },
    isNextYearDisabled() {
      return this.currentYear >= new Date().getFullYear() + 10;
    },
  },
  methods: {
    generateDefaultMonths() {
      return Array.from({ length: 12 }, (_, i) =>
        new Date(0, i).toLocaleString(this.$store.state.language.lang, {
          month: "short",
        })
      );
    },
    generateMonthName() {
      return new Date(this.currentYear, this.currentMonth).toLocaleString(
        "default",
        { month: "long" }
      );
    },
    toggleYearMonthSelector() {
      this.showYearMonthSelector = !this.showYearMonthSelector;
    },
    prev() {
      this.showYearMonthSelector ? this.currentYear-- : this.prevMonth();
    },
    next() {
      this.showYearMonthSelector ? this.currentYear++ : this.nextMonth();
    },
    prevMonth() {
      if (--this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
    },
    nextMonth() {
      if (++this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      }
    },
    isDateDisabled(date) {
      return (
        new Date(this.minDate).setHours(0, 0, 0, 0) >
        new Date(this.currentYear, this.currentMonth, date).setHours(0, 0, 0, 0)
      );
    },
    isMonthDisabled(month) {
      return (
        new Date(this.currentYear, this.months.indexOf(month) + 1, 0) <
        this.minDate
      );
    },
    isSelected(date) {
      return (
        this.selectedDate?.getDate() === date &&
        this.selectedDate.getMonth() === this.currentMonth &&
        this.selectedDate.getFullYear() === this.currentYear
      );
    },
    isMonthSelected(month) {
      return this.currentMonthName.startsWith(month);
    },
    selectMonth(month) {
      if (!this.isMonthDisabled(month)) {
        this.currentMonth = this.months.indexOf(month);
        this.showYearMonthSelector = false;
      }
    },
    selectDate(date) {
      if (date && !this.isDateDisabled(date)) {
        this.selectedDate = new Date(this.currentYear, this.currentMonth, date);

        this.showYearMonthSelector = false;
        this.$emit("selectDate", this.selectedDate);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.month-selector {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate(58px, 44px);
}
.month-caret {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  left: 50%;
  top: 0;
  background-color: #f5f5f5 !important;
  transition: left 0.15s ease;
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-top: inherit;
  border-left: inherit;
  -webkit-user-select: none;
  user-select: none;
  z-index: -1;
}
.calendar-caret {
  transform: translateY(-50%) rotate(45deg);
  left: 18px;
  top: 0;
  background-color: #f5f5f5 !important;
  transition: left 0.15s ease;
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-top: inherit;
  border-left: inherit;
  -webkit-user-select: none;
  user-select: none;
  z-index: -1;
}
.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 50% 50%;
  width: 2rem;
  position: relative;
  min-height: 32px;
  z-index: 1;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5rem;
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}
</style>
