/* eslint-disable no-shadow */
export const state = () => ({
  date: null,
  dateOther: null,
  minimumDate: null,
  toggled: false,
  for: 'start',
});

export const mutations = {
  toggle(state, data) {
    state.date = data.date;
    state.dateOther = data.dateOther;
    state.minimumDate = data.minimumDate;
    state.for = data.for;
  },
};

export const actions = {

};
