const deviceDetector = ({ $device }, inject) => {
  const paDeviceDetector = {

    isMobile: () => {
      if (process.server) return false;
      return $device.isMobile;
    },
  };

  inject('paDeviceDetector', paDeviceDetector);
  inject('screens', (rule, defaultValue) => {
    if (paDeviceDetector.isMobile()) {
      return defaultValue;
    }
    if (rule instanceof Object) {
      return Object.values(rule)[0];
    }
    return defaultValue;
  });
};

export default deviceDetector;
