<template>
  <div v-if="isUSDomain" class="opacity-0 lg:opacity-100">
    <nuxt-img
      src="/_nuxt_home_page/assets/img/usa-map.png"
      alt="usa-map"
      width="auto"
      height="auto"
    />
    <nuxt-img
      v-if="isMarkerReady"
      src="/_nuxt_home_page/assets/img/plane.png"
      alt="plane"
      width="auto"
      height="auto"
      class="absolute top-1/4 left-1/4"
    />
  </div>
  <div v-else class="opacity-50 lg:opacity-100">
    <nuxt-img
      src="/_nuxt_home_page/assets/img/worldmap-plane.svg"
      alt="worldmap"
      width="724.2"
      height="350"
    />
   </div>
</template>

<script>
export default {
  data() {
    return {
      isMarkerReady: false,
    };
  },
  mounted() {
    this.isMarkerReady = true;
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
  },
};
</script>

<style></style>
