<template>
  <img
    :src="`${$paths.assetsUrl}img/search-car.svg`"
    loading="lazy"
    alt="usp car"
    width="auto"
    height="auto"
  />
</template>

<script>
export default {};
</script>

<style></style>
