<template>
  <div
    v-show="showCookieBanner"
    style="z-index: 9999999;"
    class="fixed bottom-5 md:bottom-[60px] w-full z-50 flex items-end"
  >
    <div class="w-full">
      <div class="xs:container px-5 md:px-10">
        <div
          style="box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5)"
          class="text-white p-5 rounded-md md:rounded-[10px] w-full bg-[#123878]"
        >
          <div class="text-md leading-[30px]">
            <p>
              {{ $i18n("general.cookie-banner-content") }}
            </p>
          </div>
          <div
            class="flex justify-center md:justify-start items-center gap-2.5 md:gap-5 mt-5 md:mt-2.5"
          >
            <div
              role="button"
              tabindex="-1"
              @click="acceptAll"
              class="rounded leading-none h-fit w-fit text-[14px] md:text-[16px] px-2.5 md:px-6 py-2.5 bg-orange-500 cursor-pointer hover:bg-orange-400 text-white font-cocogoose whitespace-nowrap"
            >
              {{ $i18n("general.yes-i-agree") }}
            </div>
            <div
              role="button"
              tabindex="-1"
              @click="declineAll"
              class="rounded leading-none h-fit w-fit text-[14px] md:text-[16px] px-2.5 md:px-6 py-2.5 bg-orange-500 cursor-pointer hover:bg-orange-400 text-white font-cocogoose whitespace-nowrap"
            >
              {{ $i18n("general.reject-all") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCookie,
  nginxDecodeUserId,
  setCookie,
} from "~/helpers/cookieHelpers";

export default {
  data() {
    return {
      showCookieBanner: false,
    };
  },
  mounted() {
    if (!getCookie("showCookieBanner")) {
      this.showCookieBanner = true;
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "63/24",
        dl_experiment_action: "impression",
        dl_experiment_value: "banner",
      });
    }
  },
  methods: {
    acceptAll() {
      this.hideCookieBanner();
      window.dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "63/24",
        dl_experiment_action: "selection",
        dl_experiment_value: "accept_all",
      });
      this.sendEvent("accept_all", "Action Tracking", "CookieBanner");
    },
    declineAll() {
      this.hideCookieBanner();
      window.dataLayer.push({
        event: "experiment_data",
        dl_experiment_id: "63/24",
        dl_experiment_action: "selection",
        dl_experiment_value: "decline_all",
      });
      this.sendEvent("decline_all", "Action Tracking", "CookieBanner");
    },
    hideCookieBanner() {
      setCookie("showCookieBanner", "true", 7);
      this.showCookieBanner = false;
    },
    async sendEvent(ea, ec, el) {
      const { host } = window.location;
      const pageType = () =>
        window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;

      const visitorIdCookie = getCookie("uid");
      if (!visitorIdCookie) {
        console.log("[FLAGSHIP GTM] VisitorId is not defined");
        return;
      }
      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = getCookie("xpe");
      const flagshipEventsAPIEndpoint =
        this.$config.FLAGSHIP_EVENTS_API_BASE_URL;

      const response = await fetch(
        `${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cid: flagshipEnvironmentId,
            vid: visitorId,
            dl: document.location.href,
            ds: "APP",
            ev: 1,
            t: "EVENT",
            ea,
            ec,
            el,
          }),
        }
      );
      if (response.status !== 200) {
        console.error(
          `[FLAGSHIP GTM] Event could not be be sent, HTTP ${response.status}`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
