<template>
  <img
    src="/_nuxt_home_page/general/pinterest.svg"
    loading="lazy"
    alt="pinterest"
    width="24"
    height="24"
  />
</template>

<script>
export default {};
</script>

<style></style>
