<template>
  <div class="bg-[#fff7e7] md:text-center py-2.5 xs:static">
    <h5 class="text-[12px] md:text-[14px] text-[#7b3e00] font-normal m-0 mx-5">
      {{ $i18n('general.autumn-campaign') }}
    </h5>
  </div>
</template>

<script>
export default {
  name: 'AutumnCampaignBanner',
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
    if (this.$paDeviceDetector.isMobile()) {
      let bannerHeigh = this.$el.offsetHeight;
      this.$nextTick(() => {
        bannerHeigh = this.$el.offsetHeight;
      });
      window.addEventListener('scroll', () => {
        this.$el.classList.add('absolute');
        if (window.pageYOffset >= bannerHeigh) {
          this.$el.parentElement.style.paddingTop = 0;
          this.$el.style.top = `${bannerHeigh}px`;
        } else {
          this.$el.parentElement.style.paddingTop = `${
            bannerHeigh - window.pageYOffset
          }px`;
          this.$el.style.top = `${0 - window.pageYOffset}px`;
        }
      });
    }
  },
  methods: {},
};
</script>

<style scoped></style>
