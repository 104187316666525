<template>
  <img
    :src="`${$paths.assetsUrl}img/check.svg`"
    loading="lazy"
    alt="usp 24"
    width="auto"
    height="auto"
  />
</template>

<script>
export default {};
</script>

<style></style>
