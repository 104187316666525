<template>
  <CaretDown
    class="transform duration-200 transition-all stroke-current"
    :class="{ 'rotate-180 text-orange-500': show }"
  />
</template>

<script>
import CaretDown from "~/components/icons/IconCaretDown.vue";

export default {
  components: {
    CaretDown,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
};
</script>

<style></style>
