<template>
  <div v-if="lang" class="language-flag shrink-0 px-0.5 py-0 rounded bg-white mr-2 inline-block">
    <nuxt-img class="flag w-5 rounded" :alt="lang" :src="`/_nuxt_home_page/assets/img/flags/${language}.png`" />
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    language() {
      if (this.lang.includes('be')) return 'be';
      return this.lang;
    },
  },
};
</script>

<style lang="scss">
.language-flag{
  min-width: 20px;
}
</style>
