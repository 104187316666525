<template>
  <div
    v-click-outside="collapseDate"
    :class="{ customStyleHideCaret: homeSmartAutoComplete }"
    class="date-time-picker font-sans flex justify-between w-full h-full overflow-visible"
    @focusout="focusHandler"
  >
    <component
      :is="component"
      ref="datePicker"
      class="datepicker"
      :value="date"
      :locale="language"
      is-required
      :is-range="false"
      mode="date"
      :min-date="minimumDate"
      :attributes="pickerAttributes"
      :popover="dateDropdownPopoverConfig"
      :columns="screens({ sm: 1 }, 1)"
      :rows="screens({ sm: 1 }, 12)"
      :masks="{ weekdays: 'WW' }"
      @input="dateChanged"
      @popoverWillHide="datePickerWillHide"
      @popoverWillShow="datePickerWillShow"
      @dayclick="dayClick"
      @hook:mounted="loaded"
    >
      <template
        #default="{
          hidePopover,
          showPopover,
          togglePopover,
          dateDropdownExpanded,
        }"
      >
        <slot
          name="date"
          :expand="showPopover"
          :collapse="hidePopover"
          :toggle="togglePopover"
          :isExpanded="dateDropdownExpanded"
        />
      </template>
    </component>
    <div v-if="state === asyncLoadingStates.awaiting">
      <slot
        name="awaiting"
        :loadComponent="loadComponent"
        :isExpanded="dateDropdownExpanded"
      >
      </slot>
    </div>
    <div class="w-full flex" v-if="state === asyncLoadingStates.loading">
      <slot name="loading"> </slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { asyncLoadingStates } from "~/constants/states";

const storeComponentName = "datePicker";
export default {
  directives: {
    clickOutside: ClickOutside,
  },
  props: {
    placeholder: {
      type: String,
      default: () => "",
    },
    minimumDate: {
      type: Date,
      default: () => new Date(),
    },
    isEndDate: {
      type: Boolean,
      default: () => false,
    },
    date: {
      type: Date,
      default: () => null,
    },
  },
  data() {
    return {
      dateDropdownExpanded: false,
      component: null,
      unsubscribeLoad: () => 1,
      asyncLoadingStates,
      shouldRender: false,
      state: asyncLoadingStates.awaiting,
      unsusbscribeDatePickerEvents: () => 1,
      isRendered: false,
    };
  },
  computed: {
    homeSmartAutoComplete() {
      if (this.$paDeviceDetector.isMobile()) return false;
      if (this.$store?.getters?.isExperimentEnabled("homeSmartAutoComplete")) {
        return (
          this.$store?.getters?.experimentVariant("homeSmartAutoComplete") ===
          "b"
        );
      }
      return false;
    },
    isExpanded() {
      return this.dateDropdownExpanded;
    },
    anyExpanded() {
      return this.dateDropdownExpanded;
    },
    dateDropdownPopoverConfig() {
      return { hideIndicator: true };
    },
    language() {
      return this.$store.state.language.lang;
    },
    pickerAttributes() {
      if (!this.date) {
        return [];
      }
      const order = 100;
      return [
        {
          order: order - 1,
          highlight: {
            class: "skip",
            style: {
              backgroundColor: "#f06b1f",
              borderRadius: "0.25rem",
            },
            contentClass: "skip",
            contentStyle: {
              borderRadius: "5px",
              backgroundColor: "transparent",
              color: "#fff",
            },
          },
          dates: [this.date],
        },
        {
          order: order - 2,
          highlight: {
            class: "skip",
            contentClass: "skip",
            style: {
              backgroundColor: "#fad2bb",
            },
          },
          dates: [this.date],
        },
      ];
    },
  },
  mounted() {
    this.unsubscribeLoad = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type !== "components/load" ||
        this.component ||
        !mutation.payload.includes(storeComponentName) ||
        this.state === asyncLoadingStates.loading
      ) {
        return;
      }
      this.$store.commit("components/setState", {
        component: storeComponentName,
        state: asyncLoadingStates.loading,
      });
      this.state = asyncLoadingStates.loading;

      setTimeout(() => {
        this.component = () => ({
          component: import("v-calendar/lib/components/date-picker.umd.min"),
          delay: 0,
        });
      });
    });

    this.unsusbscribeDatePickerEvents = this.$store.subscribe(
      (mutation, state) => {
        if (mutation.type !== "dateTimePicker/toggle") {
          return;
        }
        this.toggle();
      }
    );
  },
  methods: {
    formatApiDate(date) {
      let day = date.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      return `${date.getFullYear()}-${month}-${day}`;
    },
    loadComponent() {
      this.state = asyncLoadingStates.loading;
      this.isRendered = true;
      setTimeout(() => {
        this.component = () => ({
          component: import("v-calendar/lib/components/date-picker.umd.min"),
          delay: 0,
        });
      });
    },
    loaded() {
      setTimeout(() => {
        this.$store.commit("components/setState", {
          component: `${storeComponentName}${this.isEndDate ? "End" : "Start"}`,
          state: asyncLoadingStates.loaded,
        });
        this.state = asyncLoadingStates.loaded;

        if (
          this.$store.state.components.toggleLIFO[
            this.$store.state.components.toggleLIFO.length - 1
          ] === storeComponentName
        ) {
          this.$store.commit("components/clearToggleLIFO");
          this.expand();
        }
        this.expand();
      });
    },
    registerDirectPickerTarget() {
      this.$store.commit("bucket/update", {
        key: "pickerInteracted",
        value: this.isEndDate ? "departureDate" : "arrivalDate",
      });
    },
    screens(rules, defaultValue) {
      if (process.server) {
        return defaultValue;
      }
      return this.$screens(rules, defaultValue);
    },
    expand() {
      if (!this.$refs.datePicker) {
        return;
      }
      this.$refs.datePicker.showPopover();
    },
    dayClick(event) {
      if (event && !event.isDisabled && this.formatApiDate(event.date) === this.formatApiDate(this.$props.date)) {
        this.$emit("dateChanged", event.date);
      }
      setTimeout(() => {
        this.registerDirectPickerTarget();
        if (!this.$refs.datePicker) {
          return;
        }
        this.$refs.datePicker.hidePopover();
      });
    },
    collapseDate() {
      setTimeout(() => {
        if (!this.$refs.datePicker) {
          return;
        }
        this.$refs.datePicker.hidePopover();
      });
    },
    collapseBoth() {
      this.collapseDate();
    },
    clearDragData() {
      this.$refs.datePicker.refreshDateParts();
      this.$refs.datePicker.dragValue = null;
      this.dragDates = {};
    },
    datePickerWillHide() {
      this.dateDropdownExpanded = false;
      this.clearDragData();
      this.$emit(
        "collapsed",
        this.dateDropdownExpanded || this.timeDropdownExpanded
      );
    },
    datePickerWillShow() {
      this.dateDropdownExpanded = true;
      this.$emit("expanded");
    },
    dateChanged(data) {
      this.$emit("dateChanged", data, this.directChange);
    },
    focusHandler(e) {
      if (e.relatedTarget) {
        if (
          e.relatedTarget.className.includes("legacy-time-picker") ||
          e.relatedTarget.className.includes("airport-picker")
        ) {
          this.collapseDate();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.customStyleHideCaret {
  .vc-popover-caret {
    display: none !important;
  }
  .vc-popover-content.direction-bottom.vc-container {
    margin-top: 25px !important ;
  }
}
.date-time-picker {
  .vc-popover-content-wrapper {
    --popover-transition-time: 0.03s ease-in-out !important;
    transition-delay: 0 !important;

    .vc-nav-arrow,
    .vc-arrow {
      &:hover,
      &:focus {
        @apply bg-transparent border-2 border-transparent text-orange-500;
      }
    }
    .vc-nav-popover-container {
      @apply bg-silver-100 border border-black-alt-200 text-black;
      .vc-nav-header {
        .vc-nav-title {
          @apply text-base font-normal text-black;
          &:hover {
            @apply bg-blue-600 text-white;
          }
        }
      }
      .vc-nav-items {
        .vc-nav-item {
          @apply border-2 border-transparent font-normal  text-base;
          &:hover {
            @apply bg-blue-600 text-white;
          }
          &.is-active {
            @apply bg-orange-500 text-white;
          }
        }
      }
    }
  }
  .vc-day {
    &.is-not-in-month {
      &.in-next-month {
        @apply hidden;
      }
    }

    @apply w-8;
    .vc-day-content,
    .vc-highlight {
      @apply w-full h-full text-base;
      &:not(.skip) {
        &:not(.is-disabled) {
          border-radius: 0 !important;
          border: none !important;
          background: transparent !important;
          color: #000 !important;
          font-weight: 400 !important;
          &:hover {
            background: #206bf6 !important;
            color: #fff !important;
            border-radius: 5px !important;
          }
        }
      }
      &:hover {
        background: transparent;
        cursor: default;
      }
      &:not(.is-disabled) {
        &:hover {
          color: #fff !important;
          background-color: #f06b1f !important;
          border-radius: 5px;
          cursor: pointer !important;
        }
      }
      &:focus {
        @apply bg-transparent font-normal;
      }
    }
  }
  .datepicker {
    .vc-popover-caret {
      transition: left 150ms ease;
      background-color: #f5f5f5 !important;
      left: 37px;
    }
  }
  .vc-header {
    @apply bg-silver-100 rounded-t-lg;
    padding: 16px 0 !important;
  }
  .column-from-end-2 {
    .vc-header {
      @apply rounded-r-none;
    }
  }
  .vc-title {
    font-size: 1rem !important;
    &:hover {
      @apply text-orange-500;
      opacity: 1 !important;
    }
  }
  .vc-arrow {
    @apply text-black;
    &.is-left {
      @apply pl-3 sm:pl-5;
    }
    &.is-right {
      @apply pr-3 sm:pr-3.5;
    }
    @media all and (min-width: 770px) {
      &.is-right {
        @apply mr-1;
      }
    }
    svg {
      transform: scale(0.8);
    }
    margin-top: 7px;
  }
  .vc-day-content,
  .vc-weekday,
  .vc-title,
  .vc-nav-item,
  .vc-nav-title {
    @apply font-sans;
  }
  .vc-time-picker {
    .vc-date {
      @apply hidden;
    }
    .vc-time {
      select {
        @apply bg-silver-100;
        outline: none;
        border: none;
      }
    }
    .vc-am-pm {
      @apply bg-silver-100;
      button,
      button:hover {
        &.active {
          border: 1px solid transparent !important;
          background-color: #f06b1f !important;
        }
      }
    }
  }

  .column-from-end-2 {
    .vc-weeks {
      @apply p-5 sm:pb-7 sm:p-7 sm:pr-2.5;
    }
  }

  .column-from-end-1 {
    .vc-weeks {
      @apply p-5 sm:pb-7 sm:p-7;
    }
  }
}
</style>
