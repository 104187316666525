const font = new FontFace(
  'Cocogoose',
  "url('https://assets.parkos.com/fonts/cocogoose-pro-regular-webfont.woff') format('woff'), url('https://assets.parkos.com/fonts/cocogoose-pro-regular-webfont.woff2') format('woff2'), url('/_nuxt_home_page/fonts/cocogoose-pro-regular-webfont.woff2') format('woff2'),url('/_nuxt_home_page/fonts/cocogoose-pro-regular-webfont.woff') format('woff')",
  {
    style: 'normal', weight: 'normal', display: 'swap',
  },
);

const openSansFonts = [
  { src: "url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2')", weight: 400 },
  { src: "url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2')", weight: 400 },
  { src: "url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2')", weight: 500 },
  { src: "url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2')", weight: 500 },
  { src: "url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2')", weight: 700 },
  { src: "url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2')", weight: 700 },
];

font.load().then(() => {
  document.fonts.add(font);
  openSansFonts.map((f) => new FontFace('Open Sans', f.src, { style: 'normal', weight: f.weight, display: 'swap' })).forEach((font) => {
    document.fonts.add(font);
  });
});
