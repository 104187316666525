<template>
  <img
    src="/_nuxt_home_page/general/tooltip.svg"
    loading="lazy"
    alt="tooltip"
    class="w-4 h-auto"
    width="16"
    height="16"
  />
</template>

<script>
export default {};
</script>

<style></style>
