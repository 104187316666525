<template>
  <img
    src="/_nuxt_home_page/general/checkmark.svg"
    loading="lazy"
    alt="checkmark"
    width="20"
    height="14"
  />
</template>

<script>
export default {};
</script>

<style></style>
