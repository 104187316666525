<template>
  <svg
    class="sm:hidden font-sans absolute md:-top-48 sm:-top-26 md:-right-8 lg:-right-16 h-32 xxxs:h-auto"
    :class="{
      '-top-[68px] -right-[27px] xxxs:-right-[25px] xxxs:-top-[65px]':
        showAirportsDropdown,
      'xxxs:-top-10 xs:-top-18 xs:-top-24 -top-16 -right-10':
        !showAirportsDropdown,
    }"
    width="120"
    height="120"
    viewBox="0 0 158 158"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 3</title>
    <g
      id="Final"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Landingpage_final_desktop_IT"
        transform="translate(-860.000000, -82.000000)"
      >
        <g id="Group-13" transform="translate(822.000000, 82.000000)">
          <g id="Group-3" transform="translate(38.000000, 0.000000)">
            <rect
              id="Rectangle"
              fill="#ffbd00"
              x="10.8965517"
              y="10.8965517"
              width="136.206897"
              height="136.206897"
              rx="68"
            />
            <use
              id="Rectangle"
              stroke="#ffbd00"
              mask="url(#mask-2)"
              stroke-width="8"
              stroke-dasharray="2"
              xlink:href="#path-1"
            />
            <text
              id="per-prenotare"
              transform="translate(86.618749, 96.891934) rotate(-20.000000) translate(-86.618749, -96.891934)"
              font-family="Open Sans, sans-serif"
              :font-size="fontSizeLower"
              font-weight="800"
              fill="#123878"
            >
              <tspan
                :x="isFrench || isSpanish || isDutch ? 35 : 37.6187491"
                :y="97"
              >
                {{ $i18n("general.quick-to-book") }}
              </tspan>
            </text>
            <text
              id="3-MIN"
              transform="translate(76.579658, 71.109202) rotate(-20.2) translate(-76.579658, -71.109202)"
              font-family="Open Sans, sans-serif"
              font-size="35"
              font-weight="800"
              fill="#123878"
            >
              <tspan x="26.0796584" y="80.1092018">3 MIN</tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    showAirportsDropdown: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  computed: {
    airport() {
      return this.$store.state.airport;
    },
    language() {
      return this.$store.state.language;
    },
    isSEDomain() {
      return this.language.lang.includes("se");
    },
    isSpanish() {
      return this.language.lang.includes("es");
    },
    isFrench() {
      return this.language.lang.includes("fr");
    },
    isDutch() {
      return this.language.lang.includes("nl");
    },
    isPLDomain() {
      return this.language.lang.includes("pl");
    },
    isDKDomain() {
      return this.language.lang.includes("dk");
    },
    isITDomain() {
      return this.language.lang.includes("it");
    },
    isDEDomain() {
      return this.language.lang.includes("de");
    },
    isSpecialLanguage() {
      return this.isFrench || this.isSpanish || this.isDutch;
    },
    fontSizeLower() {
      if (this.isSEDomain) {
        return 12;
      }
      if (this.isPLDomain) {
        return 17;
      }
      if (this.isDKDomain) {
        return 19;
      }
      if (this.isITDomain) {
        return 14;
      }
      if (this.isDEDomain) {
        return 16;
      }
      if (this.isDutch) {
        return 12;
      }
      return 15;
    },
  },
  methods: {
    selectValue(boolExpr, trueVal, falseVal) {
      return boolExpr ? trueVal : falseVal;
    },
  },
};
</script>

<style lang="scss">
.quick-book-badge {
  &__text {
    left: 50%;
    top: 50%;
    transform: translate(-48%, -46%) rotate(336deg);
    min-width: 115px;
    font-size: 1.2rem;
    &::first-line {
      font-size: 25px;
      line-height: 22px;
    }
  }
}
</style>
